import Model from './Model.js';


class ClientAccountModel extends Model {
    constructor(values, config = {}){
        super(config);

        this.prop('id', Number, true);
        this.prop('parent', ClientAccountModel, true);
        this.prop('name', String, false);
        this.prop('accountNumber', String, true);
        this.prop('description', String, true);

        this.populate(values, config);

    }
    static name = 'ClientAccountModel';
    static source = 'ClientAccount';
}
window[Symbol.for(ClientAccountModel.name)] = ClientAccountModel;

export default ClientAccountModel;