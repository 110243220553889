<template>
    <list-table-layout 
        v-if="loaded"
        :header="`Accounts (${accounts.length})`"
        :columns="columns"
        :model="ClientAccountModel"
        :records="accounts"
        :rowControlsWidth="3"
        class="account-list"
        >
        <!-- <template v-slot:table-controls>
            <router-link to="/accounts/create" class="button">&plus; Add account</router-link>
        </template> -->
        <!-- <template v-slot:openable="{row}" >
            <div class="components">
                <template v-if="Object.keys(row).length">
                    <div class="components">
                        <div class="component">
                            <header>
                                <h3>Account details</h3>
                            </header>
                            <headless-table :columns="accountDetailsColumns" :data="row"></headless-table>
                        </div>
                        <div class="component">
                            <header>
                                <h3>Star Leasing Account Manager</h3>
                            </header>
                            <headless-table :columns="accountManagerColumns" :data="row"></headless-table>
                        </div>
                    </div>
                    <div class="component account-users">
                        <header>
                            <h3>Account users</h3>
                            <div class="header-controls">
                                <button><i class="fas fa-link"></i> Link user</button>
                            </div>
                        </header>
                        <simple-table :data="row['users']" :columns="accountUsersColumns" :model="userModel" :filtersOff="true"></simple-table>
                    </div>
                    <div class="component" v-if="Object.keys(row['related-accounts']).length">
                        <header>
                            <h3>Related accounts</h3>
                        </header>
                        <simple-table :data="row['related-accounts']" :columns="columns" :model="model" :filtersOff="true"></simple-table>
                    </div>
                </template>
            </div>
        </template>
        <template v-slot:rowcontrols="{row}">
            <a class="button" @click.prevent.stop="$root.viewAs = row.number + ' - ' + row.name"><i class="far fa-eye"></i></a>
        </template> -->
    </list-table-layout>
</template>

<script>

import ListTableLayout from '../../components/layouts/ListTableLayout.vue';
// import HeadlessTable from '../../components/tables/HeadlessTable.vue';
// import SimpleTable from '../../components/tables/SimpleTable.vue';

import { ref } from '@vue/runtime-core';
import ClientAccountModel from '../../models/ClientAccountModel';
import database from "@/store/database";

const columns = {
    'name' : {
        title : "Account",
        sortable: true
    },
    'accountNumber' : {
        title: "Account #",
        sortable: true
    },
    'parent.name' : {
        title: "Related accts",
        sortable: true,
        multiple: 'count',
        filterable: false
    },
    // 'relation-number' : {
    //     title: "Related#",
    //     sortable: true,
    // },
    // 'location' : {
    //     title: "Loc.",
    //     sortable: true,
    //     options: Locations
    // },
    // 'primary-contact' : {
    //     title: "Primary contact",
    //     sortable: true
    // }, 
    // 'manager.full-name' : {
    //     title: "Star account manager",
    //     sortable: true,
    //     link: '/users/'
    // },
};

const accountDetailsColumns = {
    'name' : {
        title: 'Account name'
    },
    'number' : {
        title: 'Account number'
    },
    'address' : {
        title: 'Account address'
    }
}

const accountManagerColumns = {
    'manager.full-name' : {
        title: 'Name'
    },
    'manager.email' : {
        title: 'Email'
    },
    'manager.phone' : {
        title: 'Phone'
    }
}

const accountUsersColumns = {
    'full-name' : {
        title: 'Name',
    },
    'user-role' : {
        title: 'Role',
    },
    'email' : {
        title: 'Email',
    },
}

export default {
    components: {
        ListTableLayout,
        // HeadlessTable,
        // SimpleTable,
    },
    data: function(){

        return {
            accountDetailsColumns: accountDetailsColumns,
            accountManagerColumns: accountManagerColumns,
            accountUsersColumns: accountUsersColumns,
        }
    },
    setup(){
        const promises = [];
        const loaded = ref(false);

        const accounts = database[ClientAccountModel.name].list;

        Promise.all(promises).then(() => {
            loaded.value = true;
        });

        return {accounts, ClientAccountModel, loaded, columns}
    },
}
</script>

<style lang="scss">
@import "../../assets/scss/variables.scss";

.account-list{
    .magic-alignment{
        th:last-child{
            width: 55px;
        }
    }
}
@media (min-width: $break-tablet + 1) and (max-width: $break-wide){
    .account-list{
        .simple-table{
            .account-users,
            .components > .components{
                width: calc(50% - #{$space-standard});
                flex: 0 0 auto;
            }
        }
    }
}
@media (min-width: $break-wide + 1){
    .account-list{
        .simple-table{
            .account-users,
            .components > .components{
                width: calc(25% - #{$space-standard});
                flex: 0 0 auto;
            }
        }
    }
}
</style>