<template>
    <div class="list-table-layout">
        <header class="page-header">
            <div>           
                <client-account-header></client-account-header>
                <h2>{{header}}</h2>
            </div>
            <div class="header-controls">
                <slot name="table-controls"></slot>
                <a class="button" :class="{'open': controlsOpen}" @click="controlsOpen = !controlsOpen"><i class="fas fa-sliders-h"></i> Filters</a>
            </div>
        </header>
        <simple-table 
            :columns="columns" 
            :model="model" 
            :records="records" 
            :row-controls-width="rowControlsWidth" 
            :lazy="true"
            :controlsOpen="controlsOpen"
        >
            <template v-slot:openable="{record}"><slot name="openable" :record="record || {}"></slot></template>
            <template v-slot:rowcontrols="{record}"><slot name="rowcontrols" :record="record || {}"></slot></template>
        </simple-table>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
// import { reactive, ref, shallowRef, toRefs } from '@vue/reactivity';
// import { computed, onBeforeUnmount, onMounted } from '@vue/runtime-core';

import ClientAccountHeader from '../clientAccounts/ClientAccountHeader.vue';
import SimpleTable from '../tables/SimpleTable.vue';
// import store from '../../store/store';

export default {
    components: {ClientAccountHeader, SimpleTable},
    props: ['columns', 'model', 'records', 'header', 'rowControlsWidth'],
    setup(){
        const controlsOpen = ref(false);
        return {controlsOpen};
    }
}
</script>

<style lang="scss">

@import "../../assets/scss/variables.scss";


.list-table-layout{
    background: $white;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    > .page-header{
        z-index: 90;
        flex: 0 0 auto;

        .header-controls{
            justify-content: flex-end;

            > div{
                width: auto;
            }
        }
    }
    .table-holder{
        flex: 1 1 auto;
        overflow: hidden;
        overflow-y: scroll;
    }

    
    > table {
        width: 100%;

    }

    > .simple-table > thead > tr > th, 
    > .simple-table > tbody > tr > td{
        &:first-child{
            padding-left: $space-wide * 2;
        }
    }
    
    > .simple-table > tbody > tr.row-openable > td:first-child{
        padding-left: 0;
    }
    > .simple-table {
        &.filters-open{
            + .simple-table{
                margin-top: 185px;
            }
        }
    }

    > .simple-table-top{
        position: fixed;
        z-index: 90;
        top: 75px;
        width: calc(100% - 75px)
    }

    .card-search{
        max-width: 500px;
        margin-top: 5px;
        margin-bottom: -5px;

        .filter-toggle{
            color: $white;
        }
    }

    .loading{
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 1000px;
    }
    table+h4{
        margin-top: $space-wide;
    }

}
.list-table-footer{
    margin-top: auto;
    background: nth($blues,10);
    border-top: 1px solid nth($blues, 9);
    padding: $space-narrow $space-standard $space-narrow $space-wide * 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid nth($blues, 9);
    box-shadow: $shadow-standard;
}

@media (max-width: $break-mobile){
    .list-table-layout{
        .page-header{
            flex-wrap: wrap;
            padding: 15px $space-standard;
            h2{
                margin: $space-narrow 0;
            }
        }

        .simple-table > thead > tr > th, 
        .simple-table > tbody > tr > td{
            &:first-child{
                padding-left: $space-narrow;
            }
        }

    }
}
</style>